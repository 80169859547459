import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import '@ingka/typography/dist/style.css';
import '@ingka/variables';
import '@ingka/grid/dist/style.css';
import '@ingka/table/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/link-list/dist/style.css';
import '@ingka/switch/dist/style.css';
import '@ingka/inline-message/dist/style.css';
import '@ingka/choice/dist/style.css';
import '@ingka/search/dist/style.css';
import '@ingka/slider/dist/style.css';
import '@ingka/toggle/dist/style.css';
import '@ingka/list/dist/style.css';
import '@ingka/list-view/dist/style.css';
import '@ingka/carousel/dist/style.css';
import '@ingka/tabs/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/status/dist/style.css';
import '@ingka/loading/dist/style.css';
import '@ingka/tooltip/dist/style.css';
import '@ingka/banner/dist/style.css';
import '@ingka/toast/dist/style.css';
import '@ingka/badge/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import '@ingka/image/style.scss';
import '@ingka/aspect-ratio-box/dist/style.css';
import 'bootstrap/scss/bootstrap-grid.scss';

import Loading, { LoadingBall } from '@ingka/loading';

import 'focus-visible';

// Note: Redux store must be the first application-level module that is imported/instantiated.
import store, { persistor } from 'core/store';
import { Config } from 'core/commonTypes';
import { setApiTargets } from 'core/ApiService';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from 'components/App';
import ErrorBoundary from 'components/ErrorBoundary';
import { LanguageProvider } from 'components/LanguageSelector';
import { Router as RemixRouter } from '@remix-run/router/dist/router';
import { ModalProvider } from 'react-modal-hook';
import { RtkQueryConfig } from 'core/rtkQuery';
import { PersistGate } from 'redux-persist/integration/react';
import log, { LogLevelDesc } from 'loglevel';
import { setApiRoot } from 'core/apiConfig';

const LoadingScreen: React.FC = () => (
  <div>
    <Loading>
      <LoadingBall />
    </Loading>
  </div>
);

type IndexProps = {
  router: RemixRouter;
};

const Index: React.FC<IndexProps> = ({ router }) => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LanguageProvider>
            <ErrorBoundary>
              <ModalProvider>
                <RouterProvider router={router} />
              </ModalProvider>
            </ErrorBoundary>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

async function configureApp(): Promise<Config> {
  const { PUBLIC_URL: publicUrl, REACT_APP_SLM_CONFIG_FILE: configFile } =
    process.env;
  const response = await fetch(`${publicUrl}/${configFile}`);
  const config: Config = await response.json();

  window['__config'] = config; // Used by E2E testing
  await setApiTargets(config);
  RtkQueryConfig.initialize(config);
  setApiRoot(config.apiRoot);

  return config;
}

async function start(): Promise<void> {
  const logLevel: LogLevelDesc =
    process.env.NODE_ENV === 'development' ? 'debug' : 'warn';
  log.setLevel(logLevel, false);

  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);

  root.render(<LoadingScreen />);

  const config = await configureApp();

  const router = createBrowserRouter(
    [
      {
        path: '*',
        element: <App config={config} />,
      },
    ],
    {
      basename: process.env.PUBLIC_URL,
    }
  );

  const render = () => root.render(<Index router={router} />);

  render();

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./components/App', render);
  }
}

serviceWorker.register();
void start();
