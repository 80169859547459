import React, { useEffect, useMemo, useState } from 'react';
import { Auth0Provider } from 'components/Auth';
import { Config } from 'core/commonTypes';
import { UserProvider } from '../contexts/UserContext';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeedbackContextProvider } from '../contexts/feedback/FeedbackContext';
import { MessageDescriptor, useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import ssm from 'views/StoreStructure/storeStructureMessages';
import pm from 'views/Parameters/parametersMessages';
import sm from 'views/Summaries/summariesMessages';
import { matchPath } from 'react-router';
import Analytics from '../contexts/Analytics/Analytics';
import { useLocation } from 'react-router-dom';
import { isMobileEnabled } from 'core/features';

/**
 * Represents route title info.
 *
 * Route titles are matches in the order specified. I.e. fallback routes are
 */
type RouteTitle = {
  // The message
  pathPattern: string;
  // The messages in hierarchy order.
  messages: MessageDescriptor[];
};

const MobileApp = React.lazy(() => import('./MobileApp/MobileApp'));
const WebApp = React.lazy(() => import('./WebApp/WebApp'));

const ROUTE_TITLES: RouteTitle[] = [
  {
    pathPattern: '/store/:buCode/maintain/*',
    messages: [cm.maintainTitle],
  },
  {
    pathPattern: '/store/:buCode/structure/division-speciality-shop',
    messages: [cm.storeStructureTitle, ssm.divisionSpecialityShop],
  },
  {
    pathPattern: '/store/:buCode/structure/speciality-shop-range-group',
    messages: [cm.storeStructureTitle, ssm.specialityShopRangeGroup],
  },
  {
    pathPattern: '/store/:buCode/structure/range-group-pa',
    messages: [cm.storeStructureTitle, ssm.rangeGroupPA],
  },
  {
    pathPattern: '/store/:buCode/structure/range-group-slid',
    messages: [cm.storeStructureTitle, ssm.rangeGroupSLID],
  },
  {
    pathPattern: '/store/:buCode/structure/*',
    messages: [cm.storeStructureTitle],
  },
  {
    pathPattern: '/store/:buCode/parameters/default',
    messages: [cm.parametersTitle, pm.defaultParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/group',
    messages: [cm.parametersTitle, pm.groupParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/article',
    messages: [cm.parametersTitle, pm.articleParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/f6',
    messages: [cm.parametersTitle, pm.f6Parameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/*',
    messages: [cm.parametersTitle],
  },
  {
    pathPattern: '/store/:buCode/summaries/time-view',
    messages: [cm.summariesTitle, sm.timeView],
  },
  {
    pathPattern: '/store/:buCode/summaries/snapshot-view',
    messages: [cm.summariesTitle, sm.snapshotView],
  },
  {
    pathPattern: '/store/:buCode/summaries/*',
    messages: [cm.summariesTitle],
  },
  {
    pathPattern: '/store/:buCode/import/*',
    messages: [cm.importTitle],
  },
  {
    pathPattern: '/store/:buCode/actions/*',
    messages: [cm.actionsTitle],
  },
  {
    pathPattern: '/store/:buCode/devtools/item-computer',
    messages: [cm.itemComputerTitle],
  },
  {
    pathPattern: '/store/:buCode/devtools/*',
    messages: [cm.devToolsTitle],
  },
];

function useRouteTitle() {
  const location = useLocation();

  return useMemo(() => {
    return ROUTE_TITLES.find(pageTitle => {
      return matchPath(pageTitle.pathPattern, location.pathname) !== null;
    });
  }, [location.pathname]);
}

/**
 * Obtains the main title message descriptor or `null` if no match could be found.
 */
export function useMainTitle(): MessageDescriptor | null {
  return useRouteTitle()?.messages[0] ?? null;
}

export type AppProps = {
  config: Config;
};

const App: React.FC<AppProps> = ({ config }) => {
  const routeTitle = useRouteTitle();
  const { $t } = useIntl();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const messages: MessageDescriptor[] = routeTitle?.messages;

    const titles = messages
      ? [
          // Show only leaf page/tab
          $t(messages[messages.length - 1]),
          // Or show all titles in reverse hierarchy order.
          // ...messages.map(message => $t(message)).reverse(),
          $t(cm.appTitle),
        ]
      : [$t(cm.appTitle)];
    document.title = titles.join(' - ');
  }, [routeTitle, $t]);

  useEffect(() => {
    const checkMobile = () => {
      if (isMobileEnabled()) {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  return (
    <Auth0Provider config={config}>
      <UserProvider>
        <ErrorBoundary>
          <Analytics config={config}>
            <FeedbackContextProvider>
              {isMobile ? <MobileApp /> : <WebApp />}
            </FeedbackContextProvider>
          </Analytics>
        </ErrorBoundary>
      </UserProvider>
    </Auth0Provider>
  );
};

export default App;
